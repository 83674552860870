import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import ProductCard from "../components/ProductCard";
import client from "../lib/client";
import { useLocalStorage } from '@uidotdev/usehooks';

const Tabs = ({ categories, selectedCategory, onSelectCategory, language }) => (
  <div className="col-12 text-">
    <ul className="nav nav-pills d-inline-flex text-center mb-5">
      <li className="nav-item">
        <Link
          className={`d-inline-flex m-2 py-2 px-3 bg-light rounded-pill ${selectedCategory === 'All Products' ? 'active' : ''}`}
          data-bs-toggle="pill"
          href="#tab-all"
          onClick={() => onSelectCategory('All Products')}
        >
          <span className="text-dark">
            {language === 'en' && 'All Products'}
            {language === 'al' && 'Të gjitha produktet'}
            {language === 'mk' && 'Сите производи'}
            {language === 'tr' && 'Tüm Ürünler'}
          </span>
        </Link>
      </li>
      {categories.map((category, index) => (
        <li className="nav-item" key={index}>
          <Link
            className={`d-inline-flex m-2 py-2 px-3 bg-light rounded-pill ${selectedCategory === category ? 'active' : ''}`}
            data-bs-toggle="pill"
            href={`#tab-${index}`}
            onClick={() => onSelectCategory(category)}
          >
            <span className="text-dark">{category}</span>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

function Product() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All Products');
  const [language, setLanguage] = useLocalStorage('language');

  useEffect(() => {
    // Fetch product data from Sanity
    client.fetch(`*[_type == "product" && language == "${language}"]{
      _id,
      title,
      slug,
      price,
      oldprice,
      description,
      "categoryTitle": category->{title},
      "thumbnailUrl": thumbnail.asset->url
    }`).then((data) => {
      setProducts(data);
      const uniqueCategories = [...new Set(data.map(product => product.categoryTitle.title))];
      setCategories(uniqueCategories);
      setSelectedCategory('All Products');
    }).catch((error) => {
      console.error('Fetch error: ', error);
    });
  }, [language]);

  const filteredProducts = selectedCategory === 'All Products'
    ? products
    : products.filter((product) => product.categoryTitle.title === selectedCategory);
  return (
    <>
      <div className="container-fluid fruite pb-5 pt-2">
        <div className="container py-5">
          <div className="tab-class text-center">
            <div className="row g-4">
              <Tabs
                categories={categories}
                selectedCategory={selectedCategory}
                onSelectCategory={setSelectedCategory}
                language={language}
              />
            </div>
            <div className="tab-content">
              <div id="tab-1" className="tab-pane fade show p-0 active">
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="row g-4">
                      {filteredProducts.map((product) => (
                        <div key={product._id} className="col-md-6 col-lg-4 col-xl-3">
                          <ProductCard data={product} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
